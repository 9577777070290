const initialState = {
  chats: [
    {
      message: 'Hi',
      time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
      senderId: 11
    },
    {
      message: 'Hello. How can I help You?',
      time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
      senderId: 2
    },
    {
      message: 'Can I get details of my last transaction I made last month?',
      time: 'Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)',
      senderId: 11
    },
    {
      message: 'We need to check if we can provide you such information.',
      time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
      senderId: 2
    },
    {
      message: 'I will inform you as I get update on this.',
      time: 'Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)',
      senderId: 2
    },
    {
      message: 'If it takes long you can mail me at my mail address.',
      time: 'Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)',
      senderId: 11
    }
  ],
  contacts: [
    {
      id: 1,
      fullName: 'Felecia Rower',
      role: 'Frontend Developer',
      about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',

      status: 'offline'
    },
  ],
  userProfile: {
    id: 11,
    avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
    fullName: 'John Doe',
    role: 'admin',
    about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false
    },
  },

  selectedUser: {
    id: 1,
    userId: 1,
    unseenMsgs: 0,
    contact: {
      id: 11,
      avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
      fullName: 'John Doe',
      role: 'admin',
      about:
        'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
      status: 'online',
      settings: {
        isTwoStepAuthVerificationEnabled: true,
        isNotificationsOn: false
      }
    },
    chat: {


      chat: [
        {
          message: 'Hi',
          time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Hello. How can I help You?',
          time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
          senderId: 2
        },
        {
          message: 'Can I get details of my last transaction I made last month?',
          time: 'Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'We need to check if we can provide you such information.',
          time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
          senderId: 2
        },
        {
          message: 'I will inform you as I get update on this.',
          time: 'Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)',
          senderId: 2
        }
      ]
    },
  },
}


const WhatsappMessages = (state = initialState, action) => {
  switch (action.type) {
    // case 'GET_USER_PROFILE':
    //   return { ...state, userProfile: action.userProfile }
    // case 'GET_CHAT_CONTACTS':
    //   console.log(action.data)
    //   return { ...state, chats: action.data.chatsContacts, contacts: action.contacts }
    // case 'SELECT_CHAT':
    //   return { ...state, selectedUser: action.data }
    // case 'SEND_MSG':
    //   // ** Add new msg to chat
    //   const newMsg = action.data.response.chat
    //   return { ...state, selectedUser: { ...state.selectedUser, chat: newMsg } }
    default:
      return state;

  }
}

export default WhatsappMessages
